<template>
    <div class="grid">
        <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
        <div class="col-12">
            <div class="card">

                <div class="p-fluid formgrid grid">

                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="firstname2" type="text" v-model="product.first_name"
                                :class="{ 'p-invalid': submitted && !product.first_name }" />
                            <label for="firstname2">Firstname</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="lastname2" type="text" v-model="product.last_name"
                                :class="{ 'p-invalid': submitted && !product.last_name }" />
                            <label for="lastname2">Lastname</label>
                        </span>
                    </div> -->

                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="password" type="password" v-model="product.password"
                                :class="{ 'p-invalid': submitted && !product.password }" />
                            <label for="password">Password</label>
                        </span>
                    </div> -->

                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="user_type" v-model="user_type_dropdownItem" :options="user_type_dropdownItems"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !user_type_dropdownItem }"></Dropdown>
                       
                        <label for="user_type">User Type</label>
                        </span>

                    </div> -->

                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="email" type="text" v-model="product.email_id"
                                :class="{ 'p-invalid': submitted && !product.email_id }" />
                            <label for="email">Email</label>
                        </span>
                    </div> -->

                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText type="text" id="mobile_number" v-model="product.mobile_number"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                maxlength="10"
                                :class="{ 'p-invalid': submitted && !product.mobile_number }" />
                            <label for="mobile_number">Mobile Number</label>
                        </span>
                    </div> -->
                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="alternate_mobile_number" type="text" v-model="product.alternate_contact_no"                                
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                maxlength="10"
                                :class="{ 'p-invalid': submitted && !product.alternate_contact_no }" />
                            <label for="alternate_mobile_number">Alternate Mobile Number</label>
                        </span>
                    </div> -->
                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                       <Dropdown id="gender" v-model="gender_dropdownItem" :options="gender_dropdownItems"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !gender_dropdownItem }"></Dropdown>
                            <label for="gender">Gender</label>
                            </span>
                    </div> -->
                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                            <span class="p-float-label">
                                                <Dropdown id="gender" v-model="employment_dropdownItem"
                                                    :options="employment_dropdownItems" optionLabel="name"  filter
                                                    :class="{ 'p-invalid': submitted && !employment_dropdownItem }">
                                                </Dropdown>
                                                <label for="employment_type">Employment Type</label>
                                            </span>
                                        </div>

                                        <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                            <span class="p-float-label">
                                                <Dropdown id="designation" v-model="designation_dropdownItem"
                                                    :options="designation_dropdownItems" optionLabel="name" filter
                                                    :class="{ 'p-invalid': submitted && !designation_dropdownItem }">
                                                </Dropdown>
                                                <label for="designation"> Select Designation</label>
                                            </span>
                                        </div> -->

                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                            <span class="p-float-label">
                                <MultiSelect 
                                    


                                     id="Category" 
                                    v-model="ticket_category_selected" 
                                    :options="ticket_category_dropdown"
                                    :value="ticket_category_dropdown" 
                                    optionLabel="name" 
                                    placeholder="Select Department"
                                    filter  
                                    
                                ></MultiSelect>
                                
                            </span>
                        </div> -->

                      


                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <MultiSelect 
                                id="ward_number" 
                                v-model="ward_no" 
                                :options="ward_number_dropdown" 
                                optionLabel="name" 
                                placeholder="Select Ward Numbers"
                                :filter="true"
                            ></MultiSelect>
                          
                        </span>
                    </div> -->



                    <!-- <div class="field col-12" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <Textarea id="address" rows="4" v-model="product.address"
                                :class="{ 'p-invalid': submitted && !product.address }" />
                            <label for="address">Address</label>
                        </span>
                    </div> -->

                    <!-- <div class="field col-12 md:col-12" style="margin-top: 1%;">
                        
                            <label for="Location">Location</label>
                        <Dropdown 
                                    @change="get_location"
                                    v-model="location_search" 
                                    :options="location_dropdown" 
                                    optionLabel="name" 
                                    filter 
                                    @filter="dropdown_list"
                                    placeholder="Location" 
                                    >
                            <template #value="slotProps">
                            <div class="country-item country-item-value" v-if="slotProps.value">
                                <div>{{slotProps.value.name}}</div>
                            </div>
                                <span v-else>
                                {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                            <span>{{slotProps.option.name}}</span>
                            </template>
                            
                        </Dropdown>
                        
                    
                    </div> -->



                    <!-- <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <Dropdown id="state" v-model="state_dropdownItem"
                                                    :options="state_dropdownItems" optionLabel="name"
                                                    placeholder="State" filter
                                                    :class="{ 'p-invalid': submitted && !state_dropdownItem }">
                                                </Dropdown>
                        
                        </span>
                    </div> -->
                    <!-- <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label"> -->

                            <!-- <Dropdown id="state" v-model="dropdownItem" filter :options="dropdownItems" optionLabel="name" placeholder="Select One"></Dropdown>
                         -->
                            <!-- <InputText id="state" type="text" v-model="product.city"   placeholder="City"
                                :class="{ 'p-invalid': submitted && !product.city }" /> -->
                            <!-- <label for="state">City</label> -->
                        <!-- </span>
                    </div> -->
                    <!-- <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="zip" type="text"  placeholder="Pincode"   v-model="product.pinvalue"
                                :class="{ 'p-invalid': submitted && !product.pinvalue }" />
                            <label for="zip">Pincode</label>
                        </span>
                    </div> -->
                    <div class="field col-12 md:col-2" style="margin-top: 1%;text-align:right">
                        <FileUpload mode="basic" id="fileupload1" ref="fileupload1" :chooseLabel="$t('upload_user')"
                            :maxFileSize="1000000" @change="fileuploaduser($event)" />
                    </div>

                    <div class="field col-12 md:col-12" style="margin-top: 1%;">
                        <DataTable :value="products" :loading="loading" :lazy="true" :rows="limit" :filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5, 10, 25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll" style="font-size: 12px" :rowHover="true" showGridlines>
                            <template #empty>
                                <b style="text-align:center">{{ $t('no_records_found') }}</b>
                            </template>
                            <template #header>
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <h5 class="m-0">
                                        <b> {{ $t('new_user_list') }}</b>
                                    </h5>
                                    {{ $t('total_record') }} : {{ this.products.length }}
                                </div>
                            </template>

                            <Column field="first_name" headerStyle="min-width:10rem;" :header="$t('firstname')">
                                <template #body="{ data }">
                                    <InputText id="first_name" type="text" size="10"
                                        v-model.trim="data.first_name" required="true" autofocus />
                                </template>
                            </Column>
                            <Column field="last_name" headerStyle="min-width:10rem;" :header="$t('lastname')">
                                <template #body="{ data }">
                                    <InputText id="last_name" type="text" size="10"
                                        v-model.trim="data.last_name" required="true" autofocus />
                                </template>
                            </Column>
                            <Column field="mobile_number" headerStyle="min-width:10rem;" :header="$t('mobile')">
                                <template #body="{ data }">
                                    <InputText id="mobile_number" type="text" size="10" v-model.trim="data.mobile_number"
                                        required="true" autofocus />
                                </template>
                            </Column>
                             <Column field="password" headerStyle="min-width:10rem;" :header="$t('password')">
                                <template #body="{ data }">
                                    <InputText id="password" type="text" size="10" v-model.trim="data.password"
                                        required="true" autofocus />
                                </template>
                            </Column>
                            <Column :header="$t('action')" headerStyle="min-width:2rem;">
              <template #body="slotProps">
               
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                />
              </template>
            </Column>
                           
                        </DataTable>
                    </div>
                    <!-- add excel functionality end -->








                    <div class="field col-12 md:col-4" style="margin-top: 1%;"></div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <!-- <Button v-if="product._id" label="Update" class="p-button-success mr-2" @click="Add_user" v-tooltip.bottom="'Update'" /> -->
                        <Button :label="$t('submit')" class="p-button-success mr-2" @click="submit_details"
                            v-tooltip.bottom="$t('submit')" />
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button :label="$t('reset')" class="p-button-danger mr-2" v-tooltip.bottom="$t('reset')" @click="reset_user" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
//import ProductService from "../service/ProductService";
import JsonExcel from "vue-json-excel";
import apis from "@/apis";
import readXlsxFile from 'read-excel-file'
import axios from "axios";
export default {
    data() {
        return {

            limit: 10,
            filters: {},
            products: [],
            status: "",
            // employment_dropdownItems: [],
            // employment_dropdownItem:null,
            // designation_dropdownItems: [],
            // designation_dropdownItem:null,
            // departments:[],
            // ward_no:[],
            // ticket_category_selected:[],
            // ticket_category_dropdown:[],
            // ward_number_dropdown:[],
            // department:null,
            // gender_dropdownItems: [
            //     { name: 'Male', value: 'Male' },
            //     { name: 'Female', value: 'Female' },
            //     { name: 'Other', value: 'Other' }
            // ],
            // gender_dropdownItem: null,


            // state_dropdownItems: [{ name: 'Andhra Pradesh', value: 'Andhra Pradesh' }, { name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' }, { name: 'Assam', value: 'Assam' }, { name: 'Bihar', value: 'Bihar' }, { name: 'Chhattisgarh', value: 'Chhattisgarh' }, { name: 'Goa', value: 'Goa' }, { name: 'Gujarat', value: 'Gujarat' }, { name: 'Haryana', value: 'Haryana' }, { name: 'Himachal Pradesh', value: 'Himachal Pradesh' }, { name: 'Jharkhand', value: 'Jharkhand' }, { name: 'Karnataka', value: 'Karnataka' }, { name: 'Kerala', value: 'Kerala' }, { name: 'Madhya Pradesh', value: 'Madhya Pradesh' }, { name: 'Maharashtra', value: 'Maharashtra' }, { name: 'Manipur', value: 'Manipur' }, { name: 'Meghalaya', value: 'Meghalaya' }, { name: 'Mizoram', value: 'Mizoram' }, { name: 'Nagaland', value: 'Nagaland' }, { name: 'Odisha', value: 'Odisha' }, { name: 'Punjab', value: 'Punjab' }, { name: 'Rajasthan', value: 'Rajasthan' }, { name: 'Sikkim', value: 'Sikkim' }, { name: 'Tamil Nadu', value: 'Tamil Nadu' }, { name: 'Telangana', value: 'Telangana' }, { name: 'Tripura', value: 'Tripura' }, { name: 'Uttar Pradesh', value: 'Uttar Pradesh' }, { name: 'Uttarakhand', value: 'Uttarakhand' }, { name: 'West Bengal', value: 'West Bengal' }],
            // state_dropdownItem: { name: "", value: "" },

            // user_type_dropdownItems: [],
            // user_type_dropdownItem: null,
            isLoading: false,
            isLoadingModel: false,
            loading: false,
            fullPage: true,
            file_attachment: '',
            id: "",
            list: [],
            client_role_group: '',








            product: {},
            submitted: false,
        }

    },
    productService: null,
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
    methods: {
    //     async getTicketCategoryMaster() {
    //   var data = {
    //     client_id: "65a4f82f6177e69880ece5d6",
        
    //   };
    //   // this.isLoadingModel = true;
    //   var promise = apis.TicketCategoryMaster(data);
    //   promise.then((response) => {
    //     // this.isLoadingModel = false;
    //     this.ticket_category_dropdown = response.data.data;
    //   });
    // },
    // async getAllEmploymentStatus() {
    //         var data = {
    //             //client_id: "65a4f82f6177e69880ece5d6",

    //         };
    //         // this.isLoadingModel = true;
    //         var promise = apis.getAllEmploymentStatus(data);
    //         promise.then((response) => {
    //             // this.isLoadingModel = false;
    //             this.employment_dropdownItems = response.data.data;
    //             console.log("employment",this.employment_dropdownItems);
    //         });
    //     },
    //     async getAllDesignation() {
    //         var data = {
    //             //client_id: "65a4f82f6177e69880ece5d6",

    //         };
    //         // this.isLoadingModel = true;
    //         var promise = apis.getAllDesignation(data);
    //         promise.then((response) => {
    //             // this.isLoadingModel = false;
    //             this.designation_dropdownItems = response.data.data;
    //             console.log("designation",this.designation_dropdownItems);
    //         });
    //     },
    //     async getWardList() {
    //   var data = {
    //     //client_id: "65a4f82f6177e69880ece5d6",
       
    //   };
    //   // this.isLoadingModel = true;
    //   var promise = apis.getWardList(data);
    //   promise.then((response) => {
    //     // this.isLoadingModel = false;
    //     this.ward_number_dropdown = response.data.data;
    //   });
    // },
    // get_user_type(client_id) {
            
            
    //         this.loading = true;
    //         var data = { 
    //            // _id: this.id
    //             _id: client_id,
    //             user_id: localStorage.getItem("id"),
    //         };
    //         var promise = apis.user_rolelist(data);
    //         promise.then((response) => {
    //             this.loading = false;
    //             console.log(response);
    //             this.list = response.data.data;
    //             if (response.data.data) {
    //                 var temp_data = [];
    //                 for (let x = 0; x < response.data.data.length; x++) {
    //                     temp_data.push({ name: response.data.data[x].user_role_name, value: response.data.data[x].user_role_name });

    //                 }
    //                 this.user_type_dropdownItems = temp_data;
    //             }

    //         });
    //     },

        confirmDeleteProduct(data)
    { 
    //  if(confirm("Are you sure you want to delete"))
    //  { 
    //   this.products = this.products.filter(item => item.mobile_number != data.mobile_number);
    //  }
                        this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        this.products = this.products.filter(item => item.mobile_number != data.mobile_number);
                        this.$swal.fire(
                        'Deleted!',
                        'User has been deleted.',
                        'success'
                        )
                    }
                    })
    },
        submit_details() {
            if (this.products == [] || this.products == null || this.products == undefined || this.products.length == 0) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Add Atlest one User",
                    life: 3000
                });
                return false;
            }
            for (var i = 0; i < this.products.length; i++) 
            {
                if (this.products[i].mobile_number) {
                    this.products[i].mobile_number=this.products[i].mobile_number.toString();
                }
                // if(this.checkName(this.products[i].name)!='')
                // {
                //   alert("Invalid Name "+this.checkName(this.products[i].name));
                //   return false;
                // }

                // if(this.checkMobile(this.products[i].mobile_number.toString())!='')
                // {

                //   alert("Invalid Password "+this.checkMobile(this.products[i].mobile_number.toString()));
                //   return false;
                // }
            }
            var data = {
                "user_details": this.products,
                "client_id": localStorage.getItem("client_id") ? localStorage.getItem("client_id") : '',
                "user_id": localStorage.getItem("id"),
                lang:this.$i18n.locale

            };
          


            this.isLoadingModel = true;
            var promise = apis.bulkuserupload_save(data);
            promise
                .then((responseapi) => {
                    this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
                    this.isLoadingModel = false;
                    this.reset_user();
                    //this.$router.push({name:"usermaster"});
                    this.$router.go(-1);
                })
                .catch((error) => {
                    
                    this.isLoadingModel = false;
                    this.reset_user();
                    this.$router.push({name:"usermaster"});



                });



        },

        formatDate(year) {
            if (!year) {
                return '';
            }
            const date = new Date(year, 0, 1); // Create a new Date object with the given year and January 1st
            const options = {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short'
            }; // Define the formatting options

            const formattedDate = date.toLocaleString('en-US', options); // Format the date as a string

            return formattedDate;
        },


        fileuploaduser(event) {
            //alert("here");

            console.log(event);
            const input = this.$refs.fileupload1;
            //console.log(input);

            if (input.files[0].name.slice((Math.max(0, input.files[0].name.lastIndexOf(".")) || Infinity) + 1) == 'xlsx') {
                readXlsxFile(input.files[0]).then((rows) => {
                    for (var i = 1; i < rows.length; i++) 
                    {
                        this.products.push({

                            first_name   :  rows[i][0],
                            last_name    :  rows[i][1],
                            mobile_number:  rows[i][2],
                            password     :  rows[i][3],
                            _id          :  '0',
                            sr_no        :  this.products.length + 1,
                            status       :  "Active",
                        });

                    }
                    //console.log(this.products);
                })
            }
            else {
                alert("Please select valid file");
            }
        },
       












        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS }
            };
        },







        reset_user() {
            this.product = {};
            this.products=[];
            this.submitted = false;
            this.status_dropdownItem = null;
            this.dropdownItem = null;
            this.state_dropdownItem = null;
            this.location_search = '';
            this.$refs.file_attachment.files = '';
            this.show_name = "Add";
        },
    },
    async created() {
        //this.productService = new ProductService();
        this.id = localStorage.getItem('id');
        this.client_id=localStorage.getItem('client_id');
        this.user_type=localStorage.getItem('user_key');
        //alert(this.user_type)
    //    if (localStorage.getItem('client_id')) 
    //    {
    //     await this.get_user_type(localStorage.getItem('client_id'));
    //    }
    //    await this.getWardList();
    //    await this.getTicketCategoryMaster();
    //    await this.getAllDesignation();
    //    await this.getAllEmploymentStatus();
    },

    components: { downloadExcel: JsonExcel },
}
</script>
